import { Text } from './Text'
import React from 'react'
import TrashIcon from '../assets/svgs/trash.svg'
import BagIcon from '../assets/svgs/shopping-bag.svg'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import NoImgPlaceholder from '../assets/no-image.jpeg'

type WishListItemProps = {
	name: string
	price: number
	gatsbyImage?: IGatsbyImageData
	onDelete: () => void
	onAddToCart: () => void
}
export const WishListItem: React.FC<WishListItemProps> = ({
	name,
	price,
	gatsbyImage,
	onDelete,
	onAddToCart,
}) => {
	return (
		<div className="flex">
			{gatsbyImage ? (
				<GatsbyImage
					className="h-[90px] w-[90px] object-cover object-center"
					image={gatsbyImage}
					alt="zdjęcie produktu"
				/>
			) : (
				<img
					src={NoImgPlaceholder}
					alt="brak zdjęcia"
					className="h-[90px] w-[90px] object-cover object-center"
				/>
			)}
			<div className="ml-4 flex max-w-[420px] flex-1 flex-col">
				<Text weight="semibold" size="xl">
					{name}
				</Text>
				<div className="flex flex-1 items-end">
					<Text weight="bold" size="xl">
						{price} zł
					</Text>
				</div>
			</div>
			<div className="flex items-end md:ml-4">
				<div className="flex max-h-[42px] items-center">
					<button
						className="group mr-5 flex cursor-pointer items-center justify-center bg-white-dark md:bg-white"
						onClick={onAddToCart}
					>
						<BagIcon className="group-hover:[&>g>path]:stroke-main-gold" />
					</button>
					<button
						className="group flex items-center justify-center bg-white-dark md:bg-white"
						onClick={onDelete}
					>
						<TrashIcon className="h-[24px] w-[24px] cursor-pointer transition-all ease-in-out group-hover:[&>g>path]:stroke-red" />
					</button>
				</div>
			</div>
		</div>
	)
}
