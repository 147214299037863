import { create } from 'zustand'
import { devtools, persist, createJSONStorage } from 'zustand/middleware'
import { STORE_KEYS } from '../constants'

export type TGlobalStore = {
	shouldDisableScrollOnBody: boolean
	setShouldShowCookiesBanner: (shouldShow: boolean) => void
	setShouldDisableScrollOnBody: (isDisabled: boolean) => void
	shouldShowCookiesBanner: boolean
}

export const useGlobalStore = create<TGlobalStore>()(
	devtools(
		persist(
			(set) => ({
				shouldDisableScrollOnBody: false,
				shouldShowCookiesBanner: true,
				setShouldShowCookiesBanner: (shouldShow: boolean) =>
					set({ shouldShowCookiesBanner: shouldShow }),
				setShouldDisableScrollOnBody: (isDisabled: boolean) =>
					set({ shouldDisableScrollOnBody: isDisabled }),
			}),
			{
				name: STORE_KEYS.global, // unique name
				storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
			}
		)
	)
)
