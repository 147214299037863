import Logo from '../assets/svgs/logo_desktop.svg'
import { Link } from 'gatsby'
import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { NavbarWishListDesktop } from './NavbarWishListDesktop'
import { NavbarCartFacade } from './NavbarCartFacade'
import { useWishListStore } from '../store/wishtlist'
import { useCartStore } from '../store/cart'

export const NavbarDesktop = () => {
	const { setIsWishListOpen, isWishListOpen } = useWishListStore()
	const { toggleCart } = useCartStore()
	const handleCartClick = () => {
		if (isWishListOpen) {
			setIsWishListOpen(false)
		}
		toggleCart()
	}
	return (
		<nav className="sticky top-0 z-30 hidden h-[110px] bg-white md:block">
			{/*<div className="mx-auto max-w-[1000px]">*/}
			<div className="relative flex h-[64px] items-center px-20 3xl:px-[15%]">
				<div className="flex gap-12 [&>a]:transition hover:[&>a]:text-main-gold">
					<Link to="/o-nas">O nas</Link>
					<Link to="/pielegnacja">Pielęgnacja</Link>
					<AnchorLink to="/#faq">FAQ</AnchorLink>
				</div>

				<div className="grow" />
				<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform select-none">
					<Link to="/" data-testid="logo-desktop">
						<Logo />
					</Link>
				</div>

				<NavbarWishListDesktop />
				<NavbarCartFacade isDesktop onCartClick={handleCartClick} />
			</div>
			<div className="flex h-[46px] items-center gap-12 border-y border-gray4 px-20 text-base-font min-[1250px]:gap-24 3xl:px-[15%] [&>a]:transition hover:[&>a]:text-main-gold">
				<Link
					to="/nowosci"
					className="bg-gradient-to-r from-main-gold to-alt-gold bg-clip-text font-bold text-transparent"
				>
					Nowości
				</Link>
				<Link
					to="/naszyjniki"
					activeClassName="text-main-gold"
					data-testid="desktop-nav-item"
				>
					Naszyjniki
				</Link>
				<Link
					to="/kolczyki"
					activeClassName="text-main-gold"
					data-testid="desktop-nav-item"
				>
					Kolczyki
				</Link>
				<Link
					to="/nausznice"
					activeClassName="text-main-gold"
					data-testid="desktop-nav-item"
				>
					Nausznice
				</Link>
				<Link
					to="/bransoletki"
					activeClassName="text-main-gold"
					data-testid="desktop-nav-item"
				>
					Bransoletki
				</Link>
				<Link
					to="/pierscionki"
					activeClassName="text-main-gold"
					data-testid="desktop-nav-item"
				>
					Pierścionki
				</Link>
				<Link
					to="/zamowienia-indywidualne"
					className="hidden whitespace-nowrap lg:block"
				>
					Zamówienia indywidualne
				</Link>
			</div>
			{/*</div>*/}
		</nav>
	)
}
