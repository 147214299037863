import { useState, useEffect } from 'react'

const isSSR = typeof window === 'undefined'

export const useIsMobileView = (width = 768): boolean => {
	const [isMobile, setIsMobile] = useState<boolean>(false)
	const [mediaQuery, setMediaQuery] = useState<MediaQueryList | null>(null)

	function handleSizeChange(query: MediaQueryListEvent | MediaQueryList): void {
		setIsMobile(query.matches)
	}

	useEffect(() => {
		if (!isSSR) {
			const mq = window.matchMedia(`(max-width: ${width}px)`)
			setMediaQuery(mq)
			handleSizeChange(mq)
			mq.addEventListener('change', handleSizeChange)
			return () => mq.removeEventListener('change', handleSizeChange)
		}
	}, [width])

	return isSSR ? false : isMobile
}
