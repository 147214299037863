import { Text } from './Text'
import { Button } from './Button'
import React, { useRef } from 'react'
import EmptyCartIllustration from '../assets/svgs/empty_cart.svg'
import { useClickedOutside } from '../hooks/clickOutside.hook'

type WishListProps = {
	children?: React.ReactNode
	onClose: () => void
	isOpen: boolean
	onAddAllToCart: () => void
	priceTotal: number
}

export const WishListDesktop: React.FC<WishListProps> = ({
	children,
	isOpen,
	onClose,
	onAddAllToCart,
	priceTotal,
}) => {
	const hasAnyItems = React.Children.count(children) > 0

	return (
		<div
			data-testid="desktop-navbar-wishlist-modal"
			role="dialog"
			aria-labelledby="desktopWishlistTitle"
			aria-describedby="desktopWishlistDescription"
			className={`${
				isOpen ? 'pointer-events-auto visible' : 'pointer-events-none hidden'
			} !absolute !right-[24px] !top-[42px] z-10 max-w-[580px] bg-white
			`}
		>
			<div className="flex min-h-full flex-col bg-white px-4 py-8 shadow-3xl md:pb-4">
				<Text
					className="mb-6 text-3xl md:text-2xl"
					weight="bold"
					id="desktopWishlistTitle"
				>
					Lista życzeń
				</Text>
				{hasAnyItems && (
					<div className="flex flex-col gap-8 md:min-w-[400px]">{children}</div>
				)}

				{hasAnyItems ? (
					<div className="mb-6 mt-12 flex flex-grow items-end justify-between">
						<Text color="gray2">Suma</Text>
						<Text color="gray2" weight="bold" className="text-3xl">
							{priceTotal} zł
						</Text>
					</div>
				) : (
					<div className="mt-12 flex flex-grow flex-col">
						<div className="flex">
							<EmptyCartIllustration
								role="img"
								aria-describedby="empty_kid_magnifier"
							/>
						</div>
						<div className="flex-grow">
							<h2 className="mb-4 text-center text-2xl font-semibold text-base-font">
								Pusto tu...
							</h2>
							<p className="text-center font-normal">
								Czas na biżuteryjne odkrycia!
							</p>
							<p
								className="mb-6 text-center font-normal"
								id="desktopWishlistDescription"
							>
								Tu znajdziesz wszystkie swoje ulubione produkty!
							</p>
						</div>
					</div>
				)}
				{hasAnyItems && (
					<Button
						onClick={onAddAllToCart}
						text="Dodaj wszystko do koszyka"
						className="mb-4 h-[48px]"
					/>
				)}
				<Button
					onClick={() => onClose()}
					text="Wyjdź"
					className="h-[48px] md:hidden"
				/>
			</div>
		</div>
	)
}
