import { TCartProduct, TCartUIProduct, TCheckoutForm } from '../types'

export const mergedProducts = (products: TCartProduct[]): TCartUIProduct[] => {
	return products.reduce<TCartUIProduct[]>((acc, curr) => {
		const isSizeable = curr?.availableSizes !== undefined
		const existingProductIndex = acc.findIndex((p) => p.id === curr.id)
		// we found a product that is already in the UI
		if (existingProductIndex !== -1) {
			// increment its quantity
			return acc.map((p, i) => {
				if (i === existingProductIndex) {
					return {
						...p,
						quantity: p.quantity + 1,
						sizes: p.sizes
							? [...p.sizes, ...(curr.selectedSize ? [curr.selectedSize] : [])]
							: undefined,
					}
				} else {
					return p
				}
			})
		} else {
			return [
				...acc,
				{
					...curr,
					quantity: 1,
					sizes: isSizeable
						? curr.selectedSize
							? [curr.selectedSize]
							: undefined
						: undefined,
				},
			]
		}
	}, [])
}
