export const notifyAboutProductAvailability = async (
	productId: number,
	email: string
) => {
	const response = await fetch(
		`${process.env.GATSBY_API_URL}/products/${productId}/wishlist`,
		{
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({
				email,
			}),
		}
	)
	return response
}
