import { WishList } from '../ui/WishList'
import type { TCartProduct, TProduct } from '../types'
import React from 'react'
import { WishListItem } from '../ui/WishListItem'
import HeartIcon from '../assets/svgs/heart.svg'
import { useWishListStore } from '../store/wishtlist'
import { useCartStore } from '../store/cart'

type NavbarWishListProps = {
	onDelete: (id: number) => void
	onAddToCart: (product: TCartProduct) => void
	onAddAllToCart: () => void
}
export const NavbarWishList: React.FC<NavbarWishListProps> = ({
	onAddAllToCart,
}) => {
	const {
		setIsWishListOpen,
		isWishListOpen,
		wishListProducts,
		removeProductFromWishList,
	} = useWishListStore()
	const { isCartOpen, setIsCartOpen, addCartProduct } = useCartStore()
	const priceTotal = wishListProducts.reduce((acc, product) => {
		return acc + product.price
	}, 0)
	return (
		<>
			<button
				data-testid="mobile-navbar-wishlist-icon"
				className="relative mr-8 inline-block cursor-pointer overflow-visible hover:opacity-80"
				onClick={() => {
					if (isCartOpen) {
						setIsCartOpen(false)
					}
					setIsWishListOpen(!isWishListOpen)
				}}
			>
				<span
					className={`!absolute !bottom-[-8px] !right-[-8px] flex h-[20px] w-[20px] items-center justify-center rounded-full border-2
							border-white bg-red px-2 py-0.5 text-xs text-white ${
								wishListProducts.length > 0 ? 'visible' : 'invisible'
							}`}
				>
					{wishListProducts.length}
				</span>
				<HeartIcon
					className={`
							transition-all ease-in-out ${
								wishListProducts.length > 0 && !isWishListOpen
									? '[&>path]:stroke-red'
									: ''
							} ${isWishListOpen ? 'fill-red [&>path]:stroke-red' : ''}  
						`}
				/>
				{/*{wishListProducts.length > 0 && (*/}
				{/*	<span className="absolute -bottom-3 -right-1 text-sm font-bold text-base-font">*/}
				{/*		{wishListProducts.length}*/}
				{/*	</span>*/}
				{/*)}*/}
			</button>
			{isWishListOpen ? (
				<WishList
					onClose={() => setIsWishListOpen(false)}
					priceTotal={priceTotal}
					onAddAllToCart={onAddAllToCart}
				>
					{wishListProducts.map((product) => (
						<WishListItem
							gatsbyImage={product.mainImage?.gatsbyImage}
							key={product.id}
							name={product.name}
							price={product.price}
							onDelete={() => removeProductFromWishList(product.id)}
							onAddToCart={() => addCartProduct({ ...product })}
						/>
					))}
				</WishList>
			) : null}
		</>
	)
}
