import { NavbarCartDesktop } from './NavbarCartDesktop'
import { NavbarCart } from './NavbarCart'
import { useCartStore } from '../store/cart'
import { NavbarCartProps } from '../types'
import React from 'react'

type NavbarCartFacadeProps = {
	isDesktop?: boolean
	onCartClick: () => void
}
export const NavbarCartFacade: React.FC<NavbarCartFacadeProps> = ({
	isDesktop = false,
	onCartClick,
}) => {
	const {
		setIsCartOpen,
		isCartOpen,
		cartProducts,
		addCartProduct,
		decrementProductQuantity,
		deleteCartProduct,
	} = useCartStore()

	const calculateTotalPrice = () => {
		let totalPrice = 0
		cartProducts.forEach((product) => {
			totalPrice += product.price
		})
		return totalPrice
	}
	const cartProps: NavbarCartProps = {
		onCartClick,
		isCartOpen,
		setIsCartOpen,
		removeProduct: deleteCartProduct,
		cartProducts,
		decrementProductQuantity,
		addToCart: addCartProduct,
		totalPrice: calculateTotalPrice(),
	}
	return isDesktop ? (
		<NavbarCartDesktop {...cartProps} />
	) : (
		<NavbarCart {...cartProps} />
	)
}
