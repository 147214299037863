import { datadogRum } from '@datadog/browser-rum'

export const initDatadogRum = () =>
	datadogRum.init({
		applicationId: '9d0d86fc-578c-4876-a4b1-595bef3dccd9',
		clientToken: 'pubcef22f0dc817fa351b473c559bdade11',
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: 'datadoghq.com',
		service: 'nefee-fe',
		env: 'prod',
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 50,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
	})
