import { Text } from './Text'
import { Button } from './Button'
import React from 'react'
import EmptyCartIllustration from '../assets/svgs/empty_cart.svg'

type WishListProps = {
	children?: React.ReactNode
	onClose: () => void
	onAddAllToCart: () => void
	priceTotal: number
}

export const WishList: React.FC<WishListProps> = ({
	children,
	onClose,
	onAddAllToCart,
	priceTotal,
}) => {
	const hasAnyItems = React.Children.count(children) > 0
	return (
		<div
			className="fixed bottom-0 left-0 right-0 top-[65px] z-20 bg-white"
			data-testid="mobile-navbar-wishlist-modal"
			role="dialog"
			aria-labelledby="mobileWishlistTitle"
		>
			<div className="flex min-h-full flex-col bg-white px-4 py-8">
				<Text className="mb-6 text-3xl" weight="bold" id="mobileWishlistTitle">
					Lista życzeń
				</Text>
				{hasAnyItems && <div className="flex flex-col gap-8 ">{children}</div>}

				{hasAnyItems ? (
					<div className="mb-6 mt-12 flex flex-grow items-end justify-between">
						<Text color="gray2">Suma</Text>
						<Text color="gray2" weight="bold" className="text-3xl">
							{priceTotal} zł
						</Text>
					</div>
				) : (
					<div className="mt-12 flex flex-grow flex-col">
						<div className="flex">
							<EmptyCartIllustration />
						</div>
						<div className="flex-grow">
							<h2 className="mb-4 text-center text-2xl font-semibold text-base-font">
								Pusto tu...
							</h2>
						</div>
					</div>
				)}
				{hasAnyItems && (
					<Button
						onClick={onAddAllToCart}
						text="Dodaj wszystko do koszyka"
						className="mb-4 h-[48px]"
					/>
				)}
				<Button onClick={() => onClose()} text="Wyjdź" className="h-[48px] " />
			</div>
		</div>
	)
}
