import React from 'react'

type TextProps = {
	children: React.ReactNode
	weight?: 'bold' | 'normal' | 'semibold'
	size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
	color?: 'gray2' | 'base-font' | 'main-gold' | 'alt-font'
	onClick?: () => void
	id?: string
	className?: string
	'data-testid'?: string
}
export const Text: React.FC<TextProps> = ({
	children,
	weight = 'normal',
	size = 'md',
	color = 'base-font',
	className = '',
	id,
	onClick,
	'data-testid': dataTestId,
}) => {
	const computedClassName = `font-${weight} text-${size} text-${color} ${className}`
	// change p element to title if font is larger then lg
	if (size === 'xl' || size === '2xl' || size === '3xl' || size === '4xl') {
		return (
			<h1
				data-testid={dataTestId}
				className={`font-lora ${computedClassName}`}
				onClick={onClick}
				id={id}
			>
				{children}
			</h1>
		)
	}
	return (
		<p
			className={`font-lora ${computedClassName}`}
			onClick={onClick}
			id={id}
			data-testid={dataTestId}
		>
			{children}
		</p>
	)
}
