import React, { useEffect, useState } from 'react'
import MenuIcon from '../assets/svgs/menu.svg'
import Logo from '../assets/svgs/logo_desktop.svg'
import FacebookIcon from '../assets/svgs/facebook.svg'
import InstaIcon from '../assets/svgs/insta2.svg'
import CloseIcon from '../assets/svgs/close.svg'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { NavbarWishList } from './NavbarWishList'
import { useWishListStore } from '../store/wishtlist'
import { useCartStore } from '../store/cart'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { NavbarCartFacade } from './NavbarCartFacade'

type NavbarProps = {
	onBodyScrollDisable: (disable: boolean) => void
}
export const NavbarMobile: React.FC<NavbarProps> = ({
	onBodyScrollDisable,
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const {
		setIsWishListOpen,
		wishListProducts,
		isWishListOpen,
		removeProductFromWishList,
		resetWishList,
	} = useWishListStore()
	const {
		cartProducts,
		isCartOpen,
		setIsCartOpen,
		addCartProduct,
		toggleCart,
	} = useCartStore()
	const { pathname } = useLocation()

	useEffect(() => {
		if (isMenuOpen) {
			setIsMenuOpen(false)
		}
	}, [pathname])

	useEffect(() => {
		if (isMenuOpen || isCartOpen || isWishListOpen) {
			onBodyScrollDisable(true)
		} else {
			onBodyScrollDisable(false)
		}
	}, [isMenuOpen, isCartOpen, isWishListOpen])

	const handleMenuClick = () => {
		setIsMenuOpen((prev) => !prev)
		if (isCartOpen) {
			setIsCartOpen(false)
		}
	}

	const handleWishListClick = () => {
		if (isMenuOpen) {
			setIsMenuOpen(false)
		}
		if (isCartOpen) {
			setIsCartOpen(false)
		}

		setIsWishListOpen(true)
	}

	const handleCartClick = () => {
		if (isMenuOpen) {
			setIsMenuOpen(false)
		}
		if (isWishListOpen) {
			setIsWishListOpen(false)
		}

		toggleCart()
	}

	const handleLogoClick = () => {
		if (isMenuOpen) {
			setIsMenuOpen(false)
		}
		if (isWishListOpen) {
			setIsWishListOpen(false)
		}
		if (isCartOpen) {
			setIsCartOpen(false)
		}
	}

	const handleAddAllWishlistItemsToCart = () => {
		wishListProducts.forEach((product) => {
			addCartProduct(product)
		})
		resetWishList()
		setIsWishListOpen(false)
	}

	return (
		<nav className="relative sticky top-0 z-30 flex min-h-[65px] items-center justify-between border-b border-gray4 bg-white px-5 py-3 md:hidden">
			{isMenuOpen ? (
				<CloseIcon
					onClick={handleMenuClick}
					data-testid="mobile-navbar-menu-icon"
				/>
			) : (
				<MenuIcon
					onClick={handleMenuClick}
					data-testid="mobile-navbar-menu-icon"
				/>
			)}
			<Link
				to="/"
				onClick={handleLogoClick}
				data-testid="logo-mobile"
				className="absolute left-1/2 -translate-x-1/2"
			>
				<Logo />
			</Link>
			<div className="flex">
				<NavbarWishList
					onAddAllToCart={handleAddAllWishlistItemsToCart}
					onDelete={removeProductFromWishList}
					onAddToCart={addCartProduct}
				/>
				<NavbarCartFacade onCartClick={handleCartClick} />
			</div>

			<div
				data-testid="mobile-navbar-menu"
				className={`fixed bottom-0 left-0 right-0 top-0 z-20 mt-16 border-t border-gray4 bg-white  ${
					isMenuOpen ? 'block' : 'hidden'
				}`}
			>
				<div className="h-full overflow-y-auto p-6 font-lora text-lg [&>p]:my-6">
					<p onClick={() => handleWishListClick()}>
						Lista życzeń{' '}
						{wishListProducts.length > 0 ? `(${wishListProducts.length})` : ''}
					</p>
					<p onClick={() => handleCartClick()}>
						Twój koszyk{' '}
						{cartProducts.length > 0 ? `(${cartProducts.length})` : ''}
					</p>
					<div className="my-6 h-[1px] bg-gray4" />
					<p>
						<Link to="/nowosci" className="text-burnt-orange">
							Nowości
						</Link>
					</p>
					<p>
						<Link to="/naszyjniki" data-testid="mobile-nav-item">
							Naszyjniki
						</Link>
					</p>
					<p>
						<Link to="/kolczyki" data-testid="mobile-nav-item">
							Kolczyki
						</Link>
					</p>
					<p>
						<Link to="/nausznice" data-testid="mobile-nav-item">
							Nausznice
						</Link>
					</p>
					<p>
						<Link to="/bransoletki" data-testid="mobile-nav-item">
							Bransoletki
						</Link>
					</p>
					<p>
						<Link to="/pierscionki" data-testid="mobile-nav-item">
							Pierścionki
						</Link>
					</p>
					<p>
						<Link to="/zamowienia-indywidualne">Zamówienia indywidualne</Link>
					</p>
					<div className="my-6 h-[1px] bg-gray4" />
					<p>
						<Link to="/o-nas">O nas</Link>
					</p>
					<p>
						<Link to="/pielegnacja">Pielęgnacja</Link>
					</p>
					<AnchorLink to="/#faq" onAnchorLinkClick={() => setIsMenuOpen(false)}>
						FAQ
					</AnchorLink>
					<div className="my-6 h-[1px] bg-gray4" />
					<div className="mb-12 flex gap-8">
						<a
							aria-label="fanpage na facebooku"
							href="https://www.facebook.com/nefee.bizuteria"
							target="_blank"
							rel="noreferrer"
						>
							<FacebookIcon />
						</a>
						<a
							aria-label="fanpage na instagram"
							href="https://www.instagram.com/nefee_store"
							target="_blank"
							rel="noreferrer"
						>
							<InstaIcon />
						</a>
					</div>
				</div>
			</div>
		</nav>
	)
}
