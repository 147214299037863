import React from 'react'
import Logo from '../assets/svgs/logo_desktop.svg'
import FbIcon from '../assets/svgs/facebook.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import InstaIcon from '../assets/insta_gradient.png'
import { FooterItems } from './FooterItems'

export const Footer = () => {
	return (
		<footer className="flex flex-col bg-pink-light pt-11">
			<Logo className="mx-auto h-[60px]" />
			<div className="mx-auto mt-12 flex">
				<a
					aria-label="funpage na facebooku"
					href="https://www.facebook.com/nefee.bizuteria"
					target="_blank"
					rel="noreferrer"
				>
					<FbIcon />
				</a>
				<a
					aria-label="funpage na instagramie"
					href="https://www.instagram.com/nefee_store"
					target="_blank"
					className="relative top-[1px] ml-8 h-5 w-5"
					rel="noreferrer"
				>
					<img src={InstaIcon} className="h-5 w-5" alt="instagram page link" />
				</a>
			</div>
			<FooterItems />
			<div className="mx-auto mb-2 mt-16 text-base-font md:mb-8">
				© nefee {new Date().getFullYear()}
			</div>
		</footer>
	)
}
