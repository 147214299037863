import { z } from 'zod'
import { isValidPhoneNumber } from 'react-phone-number-input'

const zod = {
	string: () => z.string().trim().min(1, { message: 'Pole wymagane' }),
}

const BaseSchema = z.object({
	firstName: z
		.string()
		.min(3, { message: 'Imię musi być dłuższe niż 2 znaki' })
		.max(50, { message: 'Imię musi być krótsze niż 50 znaków' }),
	lastName: z
		.string()
		.min(2, { message: 'Nazwisko musi być dłuższe niż 1 znak' })
		.max(100, { message: 'Nazwisko musi być krótsze niż 100 znaków' }),
	email: zod.string().email({ message: 'Niepoprawny adres email' }),
	phoneNumber: zod
		.string()
		.refine(
			(text) => isValidPhoneNumber(text, 'PL'),
			'Niepoprawny numer telefonu'
		),
	deliveryType: z.enum(['inpost', 'manual'], {
		errorMap: () => ({ message: 'Wybierz sposób dostawy' }),
	}),
	acceptedTerms: z.literal(true, {
		errorMap: () => ({
			message:
				'Przed złożeniem zamówienia prosimy o akceptację regulaminu i polityki prywatności',
		}),
	}),
	acceptedRules: z.literal(true, {
		errorMap: () => ({
			message:
				'Przed złożeniem zamówienia prosimy o akceptację regulaminu i polityki prywatności',
		}),
	}),
})

const OrderInpostSchema = z.object({
	targetPoint: z
		.string({
			errorMap: () => ({ message: 'Wybierz punkt odbioru' }),
		})
		.min(1),
	deliveryType: z.literal('inpost'),
})

const ManualOrderSchema = z.object({
	deliveryType: z.literal('manual'),
	street: zod.string(),
	houseNumber: zod.string(),
	flatNumber: zod.string().or(z.literal('')),
	postalCode: zod
		.string()
		.regex(/^\d{2}-\d{3}$/, { message: 'Niepoprawny kod pocztowy' }),
	city: zod.string(),
})

const schemaCond = z.discriminatedUnion('deliveryType', [
	OrderInpostSchema,
	ManualOrderSchema,
])

export const OrderSchema = z.intersection(schemaCond, BaseSchema)
