import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const dot = <span className="mx-6 text-main-gold md:mx-10">·</span>
export const FooterItems = () => {
	return (
		<>
			<div className="mx-12 mt-12 hidden flex-wrap justify-center text-gray3 lg:flex">
				<Link to="/dostawa-i-platnosci">Dostawa i płatności</Link>
				{dot}
				<Link to="/pielegnacja">Pielęgnacja</Link>
				{dot}
				<Link to="/reklamacje-i-zwroty">Zwroty i reklamacje</Link>
				{dot}
				<AnchorLink to="/#faq" title="FAQ">
					FAQ
				</AnchorLink>
				{dot}
				<Link to="/regulamin">Regulamin</Link>
				{dot}
				<Link to="/kontakt">Kontakt</Link>
			</div>
			<div className="mx-12 mt-12 flex flex-wrap justify-center gap-y-4 text-gray3 lg:hidden [&>div]:flex [&>div]:w-full [&>div]:justify-center ">
				<div className="[&>a]:whitespace-nowrap [&>p]:whitespace-nowrap">
					<Link to="/pielegnacja">Pielegnacja</Link>
					{dot}
					<Link to="/reklamacje-i-zwroty">Zwroty i reklamacje</Link>
				</div>
				<div className="[&>a]:whitespace-nowrap [&>p]:whitespace-nowrap">
					<Link to="/regulamin">Regulamin</Link>
					{dot}
					<Link to="/kontakt">Kontakt</Link>
				</div>
				<div>
					<Link to="/dostawa-i-platnosci">Dostawa i płatności</Link>
				</div>
			</div>
		</>
	)
}
