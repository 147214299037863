import React, { useRef } from 'react'
import { WishListItem } from '../ui/WishListItem'
import { WishListDesktop } from '../ui/WishListDesktop'
import HeartIcon from '../assets/svgs/heart.svg'
import { useClickedOutside } from '../hooks/clickOutside.hook'
import { useWishListStore } from '../store/wishtlist'
import { useCartStore } from '../store/cart'

export const NavbarWishListDesktop: React.FC = () => {
	const {
		setIsWishListOpen,
		wishListProducts,
		isWishListOpen,
		removeProductFromWishList,
		resetWishList,
	} = useWishListStore()
	const { addCartProduct, setIsCartOpen, isCartOpen } = useCartStore()

	const wrapperRef = useRef(null)
	useClickedOutside(wrapperRef, (event) => {
		setIsWishListOpen(false)
	})
	const priceTotal = wishListProducts.reduce((acc, product) => {
		return acc + product.price
	}, 0)
	return (
		<div className="relative inline-flex items-center" ref={wrapperRef}>
			<button
				data-testid="desktop-navbar-wishlist-icon"
				role="button"
				className="relative mr-8 inline-block cursor-pointer overflow-visible hover:opacity-80"
				onClick={() => {
					if (isCartOpen) {
						setIsCartOpen(false)
					}
					setIsWishListOpen(!isWishListOpen)
				}}
			>
				<span
					className={`!absolute !bottom-[-10px] !right-[-8px] flex h-[20px] w-[20px] items-center justify-center rounded-full border-2
							border-white bg-red px-2 py-0.5 text-xs text-white ${
								wishListProducts.length > 0 ? 'visible' : 'invisible'
							}`}
				>
					{wishListProducts.length}
				</span>
				<HeartIcon
					className={`
							transition-all ease-in-out ${
								wishListProducts.length > 0 && !isWishListOpen
									? '[&>path]:stroke-red'
									: ''
							} ${isWishListOpen ? 'fill-red [&>path]:stroke-red' : ''}  
						`}
				/>
				{/*{wishListProducts.length > 0 && (*/}
				{/*	<span className="absolute -bottom-3 -right-1 text-sm font-bold text-base-font">*/}
				{/*		{wishListProducts.length}*/}
				{/*	</span>*/}
				{/*)}*/}
			</button>
			<WishListDesktop
				isOpen={isWishListOpen}
				onClose={() => setIsWishListOpen(false)}
				priceTotal={priceTotal}
				onAddAllToCart={() => {
					wishListProducts.forEach((product) => {
						addCartProduct({ ...product })
					})
					resetWishList()
					setIsWishListOpen(false)
					setIsCartOpen(true)
				}}
			>
				{wishListProducts.map((product) => (
					<WishListItem
						gatsbyImage={product.mainImage?.gatsbyImage}
						key={product.id}
						name={product.name}
						price={product.price}
						onDelete={() => removeProductFromWishList(product.id)}
						onAddToCart={() => addCartProduct({ ...product })}
					/>
				))}
			</WishListDesktop>
		</div>
	)
}
