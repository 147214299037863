import React from 'react'

type CounterProps = {
	count: number
	onIncrement?: () => void
	onDecrement?: () => void
}

type CounterButtonProps = {
	sign: '+' | '-'
	onClick?: () => void
	disabled?: boolean
}

const CounterButton: React.FC<CounterButtonProps> = ({
	sign,
	onClick,
	disabled = false,
}) => (
	<span
		onClick={() => {
			if (!disabled) {
				onClick?.()
			}
		}}
		className={`px-2 font-semibold hover:bg-alt-gold ${
			disabled ? 'text-gray7' : ''
		}`}
	>
		{sign}
	</span>
)
export const Counter: React.FC<CounterProps> = ({
	count,
	onIncrement,
	onDecrement,
}) => {
	return (
		<div className="inline cursor-pointer bg-white-dark text-xl font-bold text-black">
			<CounterButton sign="-" onClick={onDecrement} disabled={count === 1} />
			<span className="mx-2">{count}</span>
			<CounterButton sign="+" onClick={onIncrement} />
		</div>
	)
}
