import type { TProduct } from '../types'

export * from './validation'
export * from './api'

export const pickRandomProducts = (
	products: TProduct[],
	count: number
): TProduct[] => {
	const randomProducts: TProduct[] = []
	if (products.length < 4) return products
	for (let i = 0; i < count; i++) {
		const randomProduct = products[Math.floor(Math.random() * products.length)]
		if (randomProducts.filter((rp) => rp.id === randomProduct.id).length > 0) {
			i--
			continue
		}
		randomProducts.push(randomProduct)
	}
	return randomProducts
}

export const capitalizeFirstLetter = (text: string) =>
	text.charAt(0).toUpperCase() + text.slice(1)

export const truncateText = (text: string, length: number) => {
	if (text.length <= length) return text
	return text.slice(0, length) + '...'
}

export const customGtagEvent = (eventName: string, metadata: any) => {
	if (window?.gtag && process.env.NODE_ENV === 'production') {
		window?.gtag('event', eventName, metadata)
	}
}
