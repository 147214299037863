import { useEffect, RefObject } from 'react'

export const useClickedOutside = (
	elementRef: RefObject<HTMLDivElement>,
	callback: (e: Event) => void,
	isCapturing = false
): void => {
	useEffect(() => {
		const handleClick: EventListener = (event) => {
			if (!elementRef?.current?.contains?.(event.target as Node) && callback) {
				callback(event)
			}
		}

		// mousedown and touchstart because we want to capture the click initiator not waiting for mouseup touchend
		document.addEventListener('mousedown', handleClick, isCapturing)
		document.addEventListener('touchstart', handleClick, isCapturing)

		return () => {
			document.removeEventListener('mousedown', handleClick, isCapturing)
			document.removeEventListener('touchstart', handleClick, isCapturing)
		}
	}, [callback, elementRef])
}
