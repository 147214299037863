import React from 'react'
import { Text } from './Text'
import { Counter } from './Counter'
import TrashIcon from '../assets/svgs/trash.svg'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import NoImgPlaceholder from '../assets/no-image.jpeg'
import { ERingSize } from '../types'
import { Link } from 'gatsby'

type CartItemProps = {
	name: string
	price: number
	sizes?: ERingSize[]
	gatsbyImage?: IGatsbyImageData
	quantity: number
	readonly?: boolean
	checkoutMode?: boolean
	onRemove?: () => void
	onIncrement?: () => void
	onDecrement?: () => void
	onUrlUpdate?: () => void
	urlToProduct: string
}
export const CartItem: React.FC<CartItemProps> = ({
	name,
	price,
	sizes,
	gatsbyImage,
	quantity,
	onDecrement,
	onIncrement,
	onRemove,
	onUrlUpdate,
	checkoutMode = false,
	readonly = false,
	urlToProduct,
}) => {
	return (
		<div className="flex">
			{gatsbyImage ? (
				<Link to={urlToProduct} onClick={onUrlUpdate}>
					<GatsbyImage
						className="h-[90px] w-[90px] object-cover object-center"
						image={gatsbyImage}
						alt="zdjęcie produktu"
					/>
				</Link>
			) : (
				<img
					src={NoImgPlaceholder}
					alt="brak zdjęcia"
					className="h-[90px] w-[90px] object-cover object-center"
				/>
			)}
			<div className="ml-4 flex flex-1 flex-col">
				<Text
					weight="semibold"
					color="alt-font"
					className="curor-pointer grow text-lg mb:max-w-full sm:text-lg"
					data-testid="cart-item-name"
				>
					<Link to={urlToProduct} onClick={onUrlUpdate}>
						{name}
					</Link>
				</Text>
				{sizes && sizes.length > 0 && (
					<Text color="gray2">
						Rozmiar{sizes.length > 1 ? 'y' : ''} {sizes.join(', ')}
					</Text>
				)}
				<div className="flex items-center">
					<Text weight="bold" size="xl" data-testid="cart-item-price">
						{price * quantity} zł
					</Text>
					{quantity > 1 ? (
						<Text
							color="gray2"
							className="ml-2 grow self-end"
							data-testid="cart-item-price-quantity"
						>
							{Math.floor((price * quantity) / quantity)} zł/szt.
						</Text>
					) : (
						<div className="grow" />
					)}
					{!readonly && (
						<div className="group mr-4 inline" onClick={onRemove}>
							<TrashIcon className="cursor-pointer transition-all ease-in-out group-hover:[&>g>path]:stroke-red " />
						</div>
					)}
				</div>
			</div>
			<div
				className={`flex ${readonly ? 'mt-1 items-start' : ''} ${
					checkoutMode
						? 'mt-1 flex-col items-start justify-between'
						: 'items-end'
				}`}
			>
				{readonly ? (
					<>
						<p className="font-semibold">{quantity} szt.</p>
						{checkoutMode && (
							<div className="group ml-2 inline" onClick={onRemove}>
								<TrashIcon className="cursor-pointer transition-all ease-in-out group-hover:[&>g>path]:stroke-red " />
							</div>
						)}
					</>
				) : (
					<Counter
						count={quantity}
						onDecrement={onDecrement}
						onIncrement={onIncrement}
					/>
				)}
			</div>
		</div>
	)
}
