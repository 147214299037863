import './src/styles/global.css'
import Layout from './src/ui/Layout'
import React from 'react'
import { initDatadogRum } from './src/datadogRum'

const wrapPageElement = ({ element, props }) => {
	if (props.pageResources.component.default.name === 'NotFoundPage') {
		return element
	}
	return <Layout>{element}</Layout>
}

const onClientEntry = () => {
	if (process.env.NODE_ENV !== 'production') {
		return
	}
	initDatadogRum()
}

export { wrapPageElement, onClientEntry }
