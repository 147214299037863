import { NavbarMobile } from '../components/NavbarMobile'
import { Footer } from '../components/Footer'
import React, { useState } from 'react'
import { NavbarDesktop } from '../components/NavbarDesktop'
import { CookiesBanner } from '../components/CookiesBanner'

type LayoutProps = {
	children: React.ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
	const [isBodyScrollDisabled, setIsBodyScrollDisabled] = useState(false)
	return (
		<div
			className={`flex h-full flex-col ${
				isBodyScrollDisabled ? 'overflow-hidden md:overflow-auto' : ''
			}`}
		>
			<NavbarMobile onBodyScrollDisable={setIsBodyScrollDisabled} />
			<NavbarDesktop />

			<main className="relative flex-grow">{children}</main>
			<Footer />
			<CookiesBanner />
		</div>
	)
}

export default Layout
