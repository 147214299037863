export const STORE_KEYS = {
	wishList: 'nefee-storage-wish-list',
	cart: 'nefee-storage-cart',
	global: 'nefee-storage-global',
	checkout: 'nefee-checkout-cache',
}

export const CATEGORY_TO_ROUTE: Record<string, string> = {
	ring: 'pierscionki',
	necklace: 'naszyjniki',
	earring: 'kolczyki',
	bracelet: 'bransoletki',
	eardrop: 'nausznice',
}

export enum BeErrorCodes {
	GENERIC_ERROR = 'GENERIC_ERROR',
	PRODUCTS_OUT_OF_STOCK = 'PRODUCTS_OUT_OF_STOCK',
	PAYU_ORDER_CREATION_FAILED = 'PAYU_ORDER_CREATION_FAILED',
	PAYU_AUTHORIZATION_ERROR = 'PAYU_AUTHORIZATION_ERROR',
	PRODUCT_ALREADY_IN_WISHLIST = 'PRODUCT_ALREADY_IN_WISHLIST',
	PROMO_CODE_NOT_FOUND = 'PROMO_CODE_NOT_FOUND',
	PROMO_CODE_ALREADY_USED = 'PROMO_CODE_ALREADY_USED',
	PROMO_CODE_TOO_MANY_ATTEMPTS = 'PROMO_CODE_TOO_MANY_ATTEMPTS',
	PROMO_CODE_INVALID = 'PROMO_CODE_INVALID',
}

export const BE_ERROR_TO_MSG: Record<BeErrorCodes, string> = {
	[BeErrorCodes.PRODUCTS_OUT_OF_STOCK]:
		'Przepraszamy, ale niektóre z wybranych produktów są już niedostępne. Prosimy o kontakt na adres: kontakt@nefee.pl.',
	[BeErrorCodes.PAYU_ORDER_CREATION_FAILED]:
		'Przepraszamy, ale nie udało się zrealizować zamówienia. Prosimy o kontakt na adres: kontakt@nefee.pl',
	[BeErrorCodes.PAYU_AUTHORIZATION_ERROR]:
		'Przepraszamy, ale nie udało się zrealizować zamówienia. Prosimy o kontakt na adres: kontakt@nefee.pl',
	[BeErrorCodes.PRODUCT_ALREADY_IN_WISHLIST]:
		'Ten email jest już na liście oczekujących na dostępność produktu.',
	[BeErrorCodes.PROMO_CODE_NOT_FOUND]:
		'Podany kod promocyjny jest nieprawidłowy.',
	[BeErrorCodes.PROMO_CODE_ALREADY_USED]:
		'Podany kod promocyjny został już wykorzystany.',
	[BeErrorCodes.PROMO_CODE_TOO_MANY_ATTEMPTS]:
		'Przekroczono limit prób użycia kodu promocyjnego. Poczekaj chwilę i spróbuj ponownie.',
	[BeErrorCodes.PROMO_CODE_INVALID]:
		'Podany kod promocyjny jest nieprawidłowy.',
	[BeErrorCodes.GENERIC_ERROR]:
		'Przepraszamy, ale wystąpił nieoczekiwany błąd. Prosimy spróbować później.',
}

export const DISCOVER_PRODUCTS_COUNT = 3
export const DISCOVER_PRODUCTS_3_ITEMS_BREAKPOINT = 1700 // width in pixel when 2 products should turn into 3

export const DESKTOP_CAROUSEL_CHANGE_INTERVAL = 5000
