import ShoppingBagFilledIcon from '../assets/svgs/shopping-bag-filled.svg'
import ShoppingBagIcon from '../assets/svgs/shopping-bag.svg'
import { Cart } from '../ui/Cart'
import { CartItem } from '../ui/CartItem'
import React from 'react'
import type { NavbarCartProps } from '../types'
import { mergedProducts } from '../utils/cart'
import { CATEGORY_TO_ROUTE } from '../constants'

export const NavbarCart: React.FC<NavbarCartProps> = ({
	onCartClick,
	totalPrice,
	cartProducts,
	isCartOpen,
	setIsCartOpen,
	addToCart,
	decrementProductQuantity,
	removeProduct,
}) => {
	return (
		<>
			{isCartOpen ? (
				<div
					className="relative cursor-pointer overflow-visible"
					data-testid="mobile-navbar-cart-icon"
					onClick={onCartClick}
				>
					<span
						className="absolute bottom-[-8px] right-[-6px] flex h-[20px] w-[20px] items-center justify-center rounded-full border-2
        border-white bg-main-gold px-2 py-0.5 text-xs text-white"
						data-testid="mobile-navbar-cart-icon-product-count"
					>
						{cartProducts.length}
					</span>
					<ShoppingBagFilledIcon className="select-none" />
				</div>
			) : (
				<div
					className="relative cursor-pointer overflow-visible"
					data-testid="mobile-navbar-cart-icon"
					onClick={onCartClick}
				>
					{cartProducts.length > 0 && (
						<span
							data-testid="mobile-navbar-cart-icon-product-count"
							className="absolute  bottom-[-8px] right-[-6px] flex h-[20px] w-[20px] items-center justify-center rounded-full border-2
         border-white bg-main-gold px-2 py-0.5 text-xs text-white"
						>
							{cartProducts.length}
						</span>
					)}
					<ShoppingBagIcon
						className={`cursor-pointer select-none ${
							cartProducts.length > 0 ? '[&>g>path]:stroke-main-gold' : ''
						}`}
					/>
				</div>
			)}

			{isCartOpen && (
				<Cart
					onClose={() => setIsCartOpen(false)}
					priceTotal={totalPrice}
					onCheckout={() => setIsCartOpen(false)}
				>
					{mergedProducts(cartProducts).map((product) => {
						const urlToProduct = `/${CATEGORY_TO_ROUTE[product.category]}/${
							product.slug
						}`
						return (
							<CartItem
								urlToProduct={urlToProduct}
								key={product.id}
								onUrlUpdate={() => setIsCartOpen(false)}
								onIncrement={() => addToCart(product)}
								onDecrement={() => decrementProductQuantity(product.id)}
								onRemove={() => removeProduct(product.id)}
								price={product.price}
								gatsbyImage={product.mainImage?.gatsbyImage}
								quantity={product.quantity}
								name={product.name}
								sizes={product.sizes}
							/>
						)
					})}
				</Cart>
			)}
		</>
	)
}
