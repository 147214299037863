import { create } from 'zustand'
import type { TProduct } from '../types'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { STORE_KEYS } from '../constants'
import { customGtagEvent } from '../utils'

type TWishListStore = {
	wishListProducts: TProduct[]
	isWishListOpen: boolean
	setIsWishListOpen: (isOpen: boolean) => void
	addProductToWishList: (product: TProduct) => void
	removeProductFromWishList: (productId: number) => void
	resetWishList: () => void
}

export const useWishListStore = create<TWishListStore>()(
	devtools(
		persist(
			(set) => ({
				isWishListOpen: false,
				wishListProducts: [],
				setIsWishListOpen: (isOpen: boolean) => set({ isWishListOpen: isOpen }),
				addProductToWishList: (product: TProduct) => {
					customGtagEvent('wishlist', {
						message: 'added product to wishlist',
						product: product.name,
					})
					return set((state) => ({
						wishListProducts: state.wishListProducts.find(
							(wp) => wp.id === product.id
						)
							? state.wishListProducts
							: [...state.wishListProducts, product],
					}))
				},
				removeProductFromWishList: (productId: number) => {
					customGtagEvent('wishlist', {
						message: 'removed product from wishlist',
						productId: productId,
					})
					return set((state) => ({
						wishListProducts: state.wishListProducts.filter(
							(product) => product.id !== productId
						),
					}))
				},
				resetWishList: () => set({ wishListProducts: [] }),
			}),
			{
				name: STORE_KEYS.wishList, // unique name
				storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
			}
		)
	)
)
