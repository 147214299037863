import React from 'react'
import { Text } from './Text'
import { Button } from './Button'
import { navigate } from 'gatsby'
import EmptyCartIllustration from '../assets/svgs/empty_cart.svg'
import { customGtagEvent } from '../utils'
import CloseIcon from '../assets/svgs/close.svg'

type CartProps = {
	children?: React.ReactNode
	onClose: () => void
	priceTotal: number
	onCheckout?: () => void
}
export const Cart: React.FC<CartProps> = ({
	children,
	onClose,
	priceTotal,
	onCheckout,
}) => {
	const hasAnyItems = React.Children.count(children) > 0
	return (
		<div
			className="fixed bottom-0 left-0 right-0 top-[65px] z-10 overflow-y-auto bg-white"
			data-testid="mobile-navbar-cart-modal"
		>
			<div className="z-20 flex min-h-full flex-col px-4 py-8">
				<Text weight="bold" className="mb-6 flex text-3xl">
					Koszyk
				</Text>
				{hasAnyItems && (
					<div className="flex flex-col gap-8 overflow-auto">{children}</div>
				)}

				{hasAnyItems ? (
					<div className="mb-6 mt-12 flex flex-grow items-end justify-between">
						<Text color="gray2">Suma</Text>
						<Text color="gray2" weight="bold" className="text-3xl">
							{priceTotal} zł
						</Text>
					</div>
				) : (
					<>
						<div className="flex">
							<EmptyCartIllustration />
						</div>
						<div className="flex-grow">
							<h2 className="mb-4 text-center text-2xl font-semibold text-base-font">
								Pusto tu...
							</h2>
							<p className="text-center font-normal">
								Czas na biżuteryjne odkrycia!
							</p>
							<p className="mb-6 text-center font-normal">
								Twój koszyk czeka na pierwszą perełkę.
							</p>
						</div>
					</>
				)}
				<Button
					onClick={() => {
						if (hasAnyItems) {
							customGtagEvent('navigation', {
								message: 'clicked on Do Kasy in mobile cart',
							})
							onCheckout?.()
							navigate('/kasa')
						} else {
							onClose()
						}
					}}
					text={hasAnyItems ? 'Do kasy' : 'Powrót'}
					className="h-[48px]"
				/>
			</div>
		</div>
	)
}
