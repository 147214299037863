import ShoppingBagFilledIcon from '../assets/svgs/shopping-bag-filled.svg'
import ShoppingBagIcon from '../assets/svgs/shopping-bag.svg'
import { CartItem } from '../ui/CartItem'
import React, { useRef } from 'react'
import { DesktopCart } from '../ui/DesktopCart'
import { useClickedOutside } from '../hooks/clickOutside.hook'
import type { NavbarCartProps } from '../types'
import { mergedProducts } from '../utils/cart'
import { useIsMobileView } from '../hooks/isMobile.hook'
import { CATEGORY_TO_ROUTE } from '../constants'

export const NavbarCartDesktop: React.FC<NavbarCartProps> = ({
	onCartClick,
	isCartOpen,
	setIsCartOpen,
	cartProducts,
	addToCart,
	decrementProductQuantity,
	totalPrice,
	removeProduct,
}) => {
	const wrapperRef = useRef(null)
	const isMobile = useIsMobileView()
	useClickedOutside(wrapperRef, (event) => {
		if (!isMobile) {
			setIsCartOpen(false)
		}
	})

	return (
		<div className="relative inline-flex items-center" ref={wrapperRef}>
			<button
				className="relative cursor-pointer overflow-visible"
				role="button"
				data-testid="desktop-navbar-cart-icon"
				onClick={onCartClick}
			>
				{cartProducts.length > 0 && (
					<span
						data-testid="desktop-navbar-cart-icon-product-count"
						className="absolute bottom-[-8px] right-[-6px] flex h-[20px] w-[20px] items-center justify-center rounded-full border-2
        border-white bg-main-gold px-2 py-0.5 text-xs text-white"
					>
						{cartProducts.length}
					</span>
				)}
				{isCartOpen ? (
					<ShoppingBagFilledIcon className="select-none" />
				) : (
					<ShoppingBagIcon
						className={`cursor-pointer select-none ${
							cartProducts.length > 0 && !isCartOpen
								? '[&>g>path]:stroke-main-gold'
								: ''
						}`}
					/>
				)}
			</button>

			{isCartOpen && (
				<DesktopCart
					onClose={() => setIsCartOpen(false)}
					priceTotal={totalPrice}
					onCheckout={() => setIsCartOpen(false)}
				>
					{mergedProducts(cartProducts).map((product) => {
						const urlToProduct = `/${CATEGORY_TO_ROUTE[product.category]}/${
							product.slug
						}`
						return (
							<CartItem
								urlToProduct={urlToProduct}
								key={product.id}
								onIncrement={() => addToCart(product)}
								onDecrement={() => decrementProductQuantity(product.id)}
								onRemove={() => removeProduct(product.id)}
								price={product.price}
								gatsbyImage={product.mainImage?.gatsbyImage}
								quantity={product.quantity}
								name={product.name}
								sizes={product.sizes}
							/>
						)
					})}
				</DesktopCart>
			)}
		</div>
	)
}
